import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOngoingProducts } from "app/pages/Listing/productsSelector";
import { fetchProducts } from "app/pages/Listing/productActionCreators";
import { ProductsCarousel } from "./";
import { getPartnerCode } from "app/reducers/partnerSelector";
import { getCurrentShop } from "app/reducers/shopSelector";
import { bindActionCreators } from "redux";

const PPProductsCarouselContainer = ({
	ppProducts = [],
	fetchProducts,
	productsVisibility,
	shop,
	partnerCode,
	showSmartDPOnRootUrl,
	onItemClick,
}) => {
	useEffect(() => {
		fetchProducts({ productsVisibility, shop, partnerCode });
	}, [productsVisibility, shop, partnerCode]);

	return (
		<ProductsCarousel
			products={ppProducts.slice(0, 4)}
			showSmartDPOnRootUrl={showSmartDPOnRootUrl}
			onItemClick={onItemClick}
		/>
	);
};

const mapStateToProps = state => {
	return {
		showSmartDPOnRootUrl: state.partner.showSmartDPOnRootUrl,
		ppProducts: getOngoingProducts(state),
		productsVisibility: state.productsVisibility,
		shop: getCurrentShop(state),
		partnerCode: getPartnerCode(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchProducts,
		},
		dispatch
	);
};

PPProductsCarouselContainer.propTypes = {
	ppProducts: PropTypes.array,
	fetchProducts: PropTypes.func,
	productsVisibility: PropTypes.string,
	shop: PropTypes.string,
	partnerCode: PropTypes.string,
	showSmartDPOnRootUrl: PropTypes.bool,
	onItemClick: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PPProductsCarouselContainer);
