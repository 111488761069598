import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import MerchProductListBlock from "app/pages/.shared/DynamicBlocks/blocks/MerchProductListBlock/MerchProductListBlock";
import { fetchMerchandisingApi } from "app/pages/Merchandising/merchandisingActionCreators";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { computedProductDates } from "app/utils/utils";

const MerchProductListBlockContainer = props => {
	const [productsOfMerchandising, setProductsOfMerchandising] = useState([]);
	const [title, setTitle] = useState([]);
	const { merchCode } = props;
	const { shop, partnerCode, productsVisibility } = useContext(AppGlobalsContext);

	useEffect(() => {
		fetchMerchandisingApi({ code: merchCode, shop, productsVisibility, partnerCode }).then(
			response => {
				setTitle(response?.data?.merchandising?.label);

				const newProductList = computedProductDates(response?.data?.products);

				setProductsOfMerchandising(newProductList);
			}
		);
	}, [merchCode, shop, productsVisibility, partnerCode]);

	return (
		<MerchProductListBlock
			{...props}
			productsOfMerchandising={productsOfMerchandising}
			title={title}
		/>
	);
};

MerchProductListBlockContainer.propTypes = {
	merchCode: PropTypes.string,
};

const mapStateToProps = state => {
	return { showSmartDPOnRootUrl: state.partner.showSmartDPOnRootUrl };
};

export default connect(mapStateToProps)(MerchProductListBlockContainer);
