import { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import AppGlobalsContext from "app/AppGlobalsContext";
import { BRANDS, SDP_SEARCH_MARKETING_BLOCKS, STATIC_PAGE_BLOCKS } from "app/constants";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { FIRST_MERCHANDISING_POSITION } from "src/@brand/appConstants";
import "./DynamicBlocksRenderer.scss";
import classNames from "classnames";

import sortBy from "lodash/sortBy";
import CenteredLogoBlock from "app/pages/.shared/DynamicBlocks/blocks/CenteredLogoBlock/CenteredLogoBlock";
import PhotoArticles from "app/pages/.shared/DynamicBlocks/blocks/PhotoArticles/PhotoArticles";
import SmartDPReasons from "app/pages/.shared/DynamicBlocks/blocks/SmartDPReasons/SmartDPReasons";
import MerchCarouselBlock from "app/pages/.shared/DynamicBlocks/blocks/MerchCarousel/MerchCarouselBlock";
import SmartDPBanner from "app/pages/.shared/DynamicBlocks/blocks/SmartDPBanner/SmartDPBanner";
import SmartDPReInsurance from "app/pages/.shared/DynamicBlocks/blocks/SmartDPReInsurance/SmartDPReInsurance";
import OneMerchBanner from "app/pages/.shared/DynamicBlocks/blocks/OneMerchBanner/OneMerchBanner";
import MultiBlocks from "app/pages/.shared/DynamicBlocks/blocks/MultiBlocks/MultiBlocks";
import ProductsCarouselBlock from "app/pages/.shared/DynamicBlocks/blocks/ProductsCarousel/ProductsCarouselBlock";
import MerchProductListBlockContainer from "app/pages/.shared/DynamicBlocks/blocks/MerchProductListBlock/MerchProductListBlockContainer";
import { FormattedMessage } from "react-intl";
import PaymentModesTooltip from "app/pages/Booking/Payment/PaymentData/PaymentDataModeFirst/PaymentModes/PaymentModesTooltip";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import FloatingButton from "app/pages/.shared/Popover/FloatingButton";
import { paymentTypeShape } from "app/utils/propTypes";

const DynamicBlocksRenderer = ({
	blocks = [],
	dividerComponent,
	fetchMerchandisings,
	merchandisings = [],
	alternateColors = true,
	oneMerchBannerMerchandising = {},
	userIsConnected,
	authorizeDividerComponentForMultiBlocks = true,
	availablePaymentTerms = [],
	availablePaymentMethods = [],
	shouldShowReasonToBook = true,
}) => {
	const { resolution, shop, productsVisibility, partnerCode, brand } = useContext(
		AppGlobalsContext
	);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const hasMerch = blocks.some(
		block =>
			block._type === SDP_SEARCH_MARKETING_BLOCKS.MERCH_CAROUSSEL ||
			block._type === SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER
	);

	useEffect(() => {
		if (hasMerch) {
			fetchMerchandisings({ shop, productsVisibility, partnerCode });
		}
	}, [hasMerch]);

	return (
		<div
			className={classNames({
				"dynamic-blocks-renderer": true,
				"dynamic-blocks-renderer--alternate-colors": alternateColors,
			})}
		>
			{blocks.map((block, index) => {
				let blockComponent;

				switch (block._type) {
					case STATIC_PAGE_BLOCKS.CENTERED_LOGO_BLOCK:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<CenteredLogoBlock
									title={block.title}
									text={block.text}
									image={block.image}
									color={block.color?.hex}
									cta={block.cta}
								/>
							</div>
						);
						break;
					case STATIC_PAGE_BLOCKS.PHOTO_ARTICLES:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<PhotoArticles
									title={block.title}
									articles={block.articles}
									isMobile={isMobile}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.REASONS_TO_BOOK:
						blockComponent = shouldShowReasonToBook ? (
							<div className="dynamic-blocks-renderer__item">
								<SmartDPReasons />
							</div>
						) : (
							false
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MERCH_CAROUSSEL: {
						// on ajoute temporairement ce code pour garder le même comportement que sur
						// le carousel XX

						if (merchandisings.length >= 3) {
							const merchandisingsPosition0 = merchandisings.filter(
								merchandising =>
									merchandising.position === FIRST_MERCHANDISING_POSITION &&
									block?.category?.name?.includes(
										merchandising.displayOptions?.category?.name
									)
							);

							const sortedsMerchandisingsPosition0 = sortBy(
								merchandisingsPosition0,
								"headerIndex"
							);

							const isBrandOV = brand === BRANDS.OV;

							const maxMerchCount = isBrandOV && !isMobile ? 3 : 4;

							const merchandisingListToDisplay = sortedsMerchandisingsPosition0.slice(
								0,
								maxMerchCount
							);

							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<MerchCarouselBlock
										introduction={block.intro}
										merchandisingTitle={block.merchTitle}
										merchandisings={merchandisingListToDisplay}
										title={block.title}
										customItem={
											isBrandOV &&
											!isMobile && (
												<div
													style={{
														display: "flex",
														alignItems: "center",
														position: "absolute",
														top: 0,
														right: 0,
														height: "100%",
													}}
												>
													<span
														style={{
															position: "absolute",
															top: "12px",
															fontSize: "8px",
															textTransform: "uppercase",
															color: "#333",
														}}
													>
														<FormattedMessage id="general.advertising.label" />
													</span>
													<div
														id="oan_ora_1_300x250_voyages.hp"
														style={{
															width: "300px",
															height: "250px",
														}}
													/>
												</div>
											)
										}
									/>
								</div>
							);
						}
						break;
					}
					case SDP_SEARCH_MARKETING_BLOCKS.BANNER:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<SmartDPBanner
									resolution={resolution}
									imageDesktop={block.imageDesktop}
									imageDesktopOverlay={block.imageDesktopOverlay}
									imageMobileOverlay={block.imageMobileOverlay}
									imageDesktopThumbnail={block.imageDesktopThumbnail}
									imageMobile={block.imageMobile}
									imageMobileThumbnail={block.imageMobileThumbnail}
									url={block.link}
									isImmersive={block.immersive}
									openInNewWindow={block.openInNewWindow}
								/>
							</div>
						);
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.REINSURANCE:
						if (
							!block.hideIfLogged ||
							(Boolean(block.hideIfLogged) && !userIsConnected)
						) {
							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<SmartDPReInsurance
										resolution={resolution}
										title={block.title}
										introduction={block.intro}
										image={block.image}
										thumbnail={block.thumbnail}
										text={block.text}
										openInNewWindow={block.cta?.openInNewWindow}
										buttonLabel={block.cta?.label}
										url={block.cta?.link}
									/>
								</div>
							);
						}
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER:
						if (!isEmpty(oneMerchBannerMerchandising)) {
							blockComponent = (
								<div className="dynamic-blocks-renderer__item">
									<OneMerchBanner
										resolution={resolution}
										title={block.title}
										merchandising={oneMerchBannerMerchandising}
									/>
								</div>
							);
						}
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MULTI_BLOCKS:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<MultiBlocks
									blocks={block.children}
									dividerComponent={dividerComponent}
									fetchMerchandisings={fetchMerchandisings}
									merchandisings={merchandisings}
									authorizeDividerComponentForMultiBlocks={
										authorizeDividerComponentForMultiBlocks
									}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.PP_CAROUSEL:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<ProductsCarouselBlock
									introduction={block.intro}
									title={block.title}
									cta={block.cta}
								/>
							</div>
						);
						break;
					case SDP_SEARCH_MARKETING_BLOCKS.MERCH_PRODUCT_LIST:
						blockComponent = (
							<div className="dynamic-blocks-renderer__item">
								<MerchProductListBlockContainer merchCode={block.merchCode} />
							</div>
						);
						break;

					case SDP_SEARCH_MARKETING_BLOCKS.PAYMENT_DESCRIPTION_BLOCK:
						blockComponent =
							availablePaymentTerms.length > 1 ? (
								<div className="dynamic-blocks-renderer__item">
									<div className="payment-description">
										<div className="payment-description__content">
											<h3>{block?.description[0]}</h3>
											<div className="payment-description__terms">
												<h3>
													<FormattedMessage id="payment.terms.description.payment.label" />
												</h3>

												{availablePaymentTerms?.map(
													(availablePaymentTerm, index) => {
														if (availablePaymentTerm !== 1) {
															return (
																<>
																	{index !== 1 && (
																		<h3>
																			<FormattedMessage id="payment.terms.description.link.label" />
																		</h3>
																	)}

																	<h3 className="payment-description__terms-item">
																		{availablePaymentTerm}x
																	</h3>
																</>
															);
														}
														return null;
													}
												)}

												<FloatingButton
													position="bottom"
													floatingContent={
														<PaymentModesTooltip
															paymentModes={availablePaymentMethods}
														/>
													}
													referenceComponent={
														<IconInfo width={15} height={15} />
													}
												/>
											</div>
										</div>
									</div>
								</div>
							) : null;
						break;
					default: {
						blockComponent = null;
					}
				}

				return (
					blockComponent && (
						<Fragment key={index}>
							{index > 0 && dividerComponent}
							{blockComponent}
						</Fragment>
					)
				);
			})}
		</div>
	);
};
DynamicBlocksRenderer.propTypes = {
	blocks: PropTypes.array,
	dividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	merchandisings: PropTypes.array,
	alternateColors: PropTypes.bool,
	oneMerchBannerMerchandising: PropTypes.object,
	userIsConnected: PropTypes.bool,
	authorizeDividerComponentForMultiBlocks: PropTypes.bool,
	availablePaymentTerms: PropTypes.arrayOf(paymentTypeShape),
	availablePaymentMethods: PropTypes.array,
	shouldShowReasonToBook: PropTypes.bool,
};

export default DynamicBlocksRenderer;
