import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { resolutionPropType } from "app/utils/propTypes";
import className from "classnames";
import "./SmartDPBanner.scss";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "app/utils/analytics/useAnalytics";

const IMG_SIZES = {
	medium: { width: 978 },
	small: { width: 768 },
	large: { width: 1847 },
	xlarge: { width: 1847 },
};

const SmartDPBanner = ({
	imageDesktopOverlay,
	imageMobileOverlay,
	imageDesktop,
	imageDesktopThumbnail,
	imageMobile,
	imageMobileThumbnail,
	openInNewWindow,
	url,
	resolution,
	isImmersive,
}) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const location = useLocation();
	const { track } = useAnalytics();

	const imageSource = isMobile ? imageMobile : imageDesktop;
	const thumbnail = isMobile ? imageMobileThumbnail : imageDesktopThumbnail;

	const target = openInNewWindow ? "_blank" : "_self";

	const clazz = className("smartdp-banner-flashsale", {
		"smartdp-banner-flashsale--immersive": isImmersive,
	});

	const handleImageClick = useCallback(() => {
		track("smart_dp_banner_cta_click", {
			actualUrl: location.pathname,
			redirectUrl: url,
		});
	}, [url, location.pathname]);

	const textSource = addOptionsToCloundinaryURL(
		isMobile ? imageMobileOverlay : imageDesktopOverlay,
		["f_auto", "fl_lossy"],
		{
			quality: "q_auto:best",
		}
	);

	return (
		<div className={clazz}>
			<RelativeLink
				to={{
					pathname: url,
				}}
				target={target}
				onClick={handleImageClick}
			>
				{isImmersive && !isMobile ? (
					<BackgroundProgressiveImg
						backgroundPosition={"center"}
						sizes={IMG_SIZES}
						resolution={resolution}
						src={imageSource}
						thumbnail={thumbnail}
					/>
				) : (
					<ProgressiveImg
						sizes={IMG_SIZES}
						resolution={resolution}
						src={imageSource}
						thumbnail={thumbnail}
					/>
				)}
				{textSource && (
					<div className="smartdp-banner-flashsale__foreground">
						<div className="smartdp-banner-flashsale__text">
							<img src={textSource} />
						</div>
					</div>
				)}
			</RelativeLink>
		</div>
	);
};

SmartDPBanner.propTypes = {
	imageDesktopOverlay: PropTypes.string,
	imageMobileOverlay: PropTypes.string,
	imageDesktop: PropTypes.string,
	imageDesktopThumbnail: PropTypes.string,
	imageMobile: PropTypes.string,
	imageMobileThumbnail: PropTypes.string,
	openInNewWindow: PropTypes.bool,
	isImmersive: PropTypes.bool,
	url: PropTypes.string,
	resolution: resolutionPropType,
};

export default memo(SmartDPBanner);
