import PropTypes from "prop-types";
import { memo } from "react";
import ProductList from "app/pages/Listing/ProductList/ProductList";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./MerchProductListBlock.scss";

const MerchProductListBlock = ({ title, productsOfMerchandising = [], showSmartDPOnRootUrl }) => {
	if (productsOfMerchandising.length === 0) {
		return false;
	}
	return (
		<div className="merch-product-list-block">
			{title && (
				<h3 className="merch-product-list-block__title">
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</h3>
			)}
			<ProductList
				products={productsOfMerchandising}
				showSmartDPOnRootUrl={showSmartDPOnRootUrl}
			/>
		</div>
	);
};

MerchProductListBlock.propTypes = {
	productsOfMerchandising: PropTypes.array,
	showSmartDPOnRootUrl: PropTypes.bool,
	title: PropTypes.string,
};

export default memo(MerchProductListBlock);
