import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import RelativeLink from "app/pages/.shared/RelativeLink";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import "./OneMerchBanner.scss";
import { RESOLUTION } from "app/constants";
import { stringify } from "qs";
import DecreasingPriceStamp from "app/pages/.shared/DecreasingPriceStamp/DecreasingPriceStamp";

const IMG_SIZES = {
	small: { width: 600 },
	medium: { width: 600 },
	large: { width: 350, height: 196 },
	xlarge: { width: 350, height: 196 },
};

const OneMerchBanner = ({ resolution, title = "", merchandising = {} }) => {
	const { track } = useAnalytics();
	const navigate = useNavigate();
	const location = useLocation();

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const imageUrl = isMobile ? merchandising.photoSmall?.url : merchandising.photoLarge?.url;

	const thumbnail = isMobile
		? merchandising.photoSmall?.thumbnail
		: merchandising.photoLarge?.thumbnail;

	const buttonLabel = merchandising.displayOptions?.labelCta;
	const to = merchandising.displayOptions?.redirectUrl
		? {
				pathname: merchandising.displayOptions?.redirectUrl,
		  }
		: {
				pathname: "/merch",
				search: `?${stringify({ code: merchandising.code })}`,
		  };

	const merchIntro = merchandising.displayOptions?.marketingDescription;
	const merchandisingStamp = merchandising.displayOptions?.stamp;
	const lowestPrice = merchandising.lowestPrice;
	let merchDesktopTitle = merchandising.displayOptions?.smartDP?.merchandisingTitleDesktop;
	const merchDesktopSubtitle =
		merchandising.displayOptions?.smartDP?.merchandisingSubtitleDesktop;
	if (merchDesktopSubtitle) {
		merchDesktopTitle = `${merchDesktopTitle}, ${merchDesktopSubtitle}`;
	}
	let merchMobileTitle = merchandising.displayOptions?.smartDP?.merchandisingTitleMobile;
	const merchMobileSubtitle = merchandising.displayOptions?.smartDP?.merchandisingSubtitleMobile;

	if (merchMobileSubtitle) {
		merchMobileTitle = `${merchMobileTitle}, ${merchMobileSubtitle}`;
	}

	const merchTitle = isMobile ? merchMobileTitle : merchDesktopTitle;

	const handleCTAClick = useCallback(() => {
		track("one-merch-banner_block_cta_click", {
			ctaLabel: buttonLabel,
			title: title,
			merchtitle: merchTitle,
			actualUrl: location.pathname,
			redirectUrl: to,
		});
	}, [buttonLabel, title, merchTitle, to, location.pathname]);

	const handleOneMerchClick = useCallback(() => {
		handleCTAClick();
		navigate(to);
	}, [to.pathname]);

	return (
		<div className="one-merch-banner">
			{title && (
				<h3 className="one-merch-banner__title">
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</h3>
			)}
			<div className="one-merch-banner__wrapper">
				{imageUrl && (
					<div className="one-merch-banner__image" onClick={handleOneMerchClick}>
						<ProgressiveImg
							sizes={IMG_SIZES}
							resolution={resolution}
							src={imageUrl}
							thumbnail={thumbnail}
						/>
						{(merchandisingStamp?.showLowestPrice !== false ||
							merchandisingStamp?.showLabel) && (
							<div className="one-merch-banner__stamp">
								<DecreasingPriceStamp
									showLowestPrice={merchandisingStamp?.showLowestPrice}
									showLabel={merchandisingStamp?.showLabel}
									label={merchandisingStamp?.label}
									showIcon={merchandisingStamp?.showIcon}
									icon={merchandisingStamp?.icon}
									lowestPrice={lowestPrice}
								/>
							</div>
						)}
					</div>
				)}
				<div className="one-merch-banner__container">
					{merchTitle?.length > 0 && (
						<h4 className="one-merch-banner__merch-title">
							<Paragraphs paragraphs={merchTitle} enableHTMLFormatting />
						</h4>
					)}
					<article className="one-merch-banner__merch-intro">
						<Paragraphs paragraphs={merchIntro} enableHTMLFormatting />
					</article>
					{buttonLabel && (
						<RelativeLink
							to={to}
							onClick={handleCTAClick}
							className="one-merch-banner__cta"
							variant="secondary"
							data-testid="reinsurance-cta"
						>
							{buttonLabel}
						</RelativeLink>
					)}
				</div>
			</div>
		</div>
	);
};

OneMerchBanner.propTypes = {
	resolution: PropTypes.string,
	title: PropTypes.string,
	merchandising: PropTypes.object,
};

export default memo(OneMerchBanner);
