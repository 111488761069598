import { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Paragraphs from "app/pages/.shared/Paragraphs";
import CurrentMerchCollection from "app/pages/.shared/CurrentMerchCollection/CurrentMerchCollection";
import "./MerchCarouselBlock.scss";
import { messagePropType } from "app/utils/propTypes";

const MerchCarouselBlock = ({
	title,
	introduction,
	merchandisingTitle,
	merchandisings = [],
	customItem = null,
}) => {
	const merchCarouselBlock = classNames({
		"merch-carousel-block__merchandisings": true,
		"merch-carousel-block__merchandisings--custom": customItem,
	});

	return (
		<div className="merch-carousel-block">
			{title && (
				<>
					<h2 className="merch-carousel-block__title">
						<Paragraphs paragraphs={title} enableHTMLFormatting />
					</h2>
					{introduction && (
						<div className="merch-carousel-block__introduction">
							<Paragraphs paragraphs={introduction} enableHTMLFormatting />
						</div>
					)}
				</>
			)}
			{merchandisingTitle && (
				<h3 className="merch-carousel-block__merch-title">
					<Paragraphs paragraphs={merchandisingTitle} enableHTMLFormatting />
				</h3>
			)}

			<div className={merchCarouselBlock}>
				<CurrentMerchCollection merchandisingList={merchandisings} />
				{customItem}
			</div>
		</div>
	);
};

MerchCarouselBlock.propTypes = {
	title: messagePropType,
	introduction: messagePropType,
	merchandisings: PropTypes.array,
	merchandisingTitle: messagePropType,
	customItem: PropTypes.element,
};

export default memo(MerchCarouselBlock);
