import { memo } from "react";
import PropTypes from "prop-types";
import { DynamicBlocksRendererContainer } from "../../";
import "./MultiBlocks.scss";

const MultiBlocks = ({
	blocks = [],
	dividerComponent,
	fetchMerchandisings,
	merchandisings = [],
	authorizeDividerComponentForMultiBlocks = true,
}) => {
	return (
		<div className="multi-blocks">
			<DynamicBlocksRendererContainer
				blocks={blocks}
				dividerComponent={
					authorizeDividerComponentForMultiBlocks ? (
						dividerComponent
					) : (
						<div className="multi-blocks__separator" />
					)
				}
				fetchMerchandisings={fetchMerchandisings}
				merchandisings={merchandisings}
				alternateColors={false}
			/>
		</div>
	);
};

MultiBlocks.propTypes = {
	blocks: PropTypes.array,
	dividerComponent: PropTypes.node,
	fetchMerchandisings: PropTypes.func,
	merchandisings: PropTypes.array,
	authorizeDividerComponentForMultiBlocks: PropTypes.bool,
};

export default memo(MultiBlocks);
